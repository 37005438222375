import { createContext, useState, useContext, useMemo } from "react";
import logo from "./logo.svg";
import "./App.css";
import SingleMenu from "./screens/singleMenu";
import WeeklyMenu from "./screens/weeklyMenus";
import Navbar from "./components/navbar";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SingleMenuRoute from "./routes/singleMenuRoute";
import OverviewWeeklyMenuRoute from "./routes/overviewWeeklyMenuRoute";
import LikedMenu from "./routes/likeMenuRoute";
import CookMenu from "./routes/cookMenuRoute";
import ShoppingListRoute from "./routes/shoppingListRoute";
import IncredientListContext from "./context/IncredientListContext";
import SearchMenu from "./screens/searchMenu";
import NavbarLogoTop from "./components/navbarLogo";
import "react-loading-skeleton/dist/skeleton.css";
import Loading from "./components/loading";
import LoginPage from "./screens/loginPage";
import Layout from "./components/layout";
import PrivateRoutes from "./service/auth";

function App() {
  let shoppingList = [];
  let [incList, setIncList] = useState(shoppingList);
  let value = useMemo(() => ({ incList, setIncList }), [incList]);
  //setIncList(shoppingList);

  return (
    <IncredientListContext.Provider value={value}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
        </Routes>

        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<WeeklyMenu />} />
              <Route path="/home" element={<WeeklyMenu />} />

              <Route path="recipe/:menuid" element={<SingleMenuRoute />} />
              <Route path="shoppingList" element={<ShoppingListRoute />} />
              <Route path="likedMenus" element={<LikedMenu />} />
              <Route path="search" element={<SearchMenu />} />
              <Route path="wantToMakeMenus" element={<CookMenu />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </IncredientListContext.Provider>
  );
}

export default App;
