import React, { Component, Fragment, useRef, useCallback } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import useMenuSearch from "../service/searchMenus";

const WeeklyMenuAll = (props) => {
  let { data, query, pageNumber, setPageNumber } = props;
  let MenuData = data;

  const { hasMore, loading, error } = useMenuSearch(query, pageNumber);
  const observer = useRef();

  const lastSearchElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 16);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div className="maxWidthOverview">
      <div className="row">
        {MenuData.meals.map((meal, i) => {
          if (i + 1 == MenuData.meals.length) {
            return (
              <div
                className="col-md-6 margincards "
                key={"menucard_col_" + meal.recipe.name}
                ref={lastSearchElementRef}
                id={i}
              >
                <div className="flexContainer  h-100">
                  <Card
                    className="WeeklyMenuCard h-100"
                    key={"menucard_" + meal.recipe.name}
                  >
                    <Link to={"/recipe/" + meal.recipe.id}>
                      <div className="singleCardPicture">
                        <CardMedia
                          component="img"
                          image={
                            "https://img.hellofresh.com/q_auto/recipes/" +
                            meal.recipe.imagePath
                          }
                          alt="green iguana"
                        />
                      </div>
                    </Link>

                    <div className="detailsUnderPicture">
                      <h2 className="textCardOverview">{meal.recipe.name}</h2>
                      <h4 className="subtextCardOverview">
                        {meal.recipe.headline}
                      </h4>

                      <div>
                        <div className="row containerBasicData">
                          <div className="col-4">
                            <p className="BasicDataItemHeader">Kochzeit</p>
                            <p className="BasicDataItemBody">
                              {meal.recipe.prepTime.slice(2)}
                            </p>
                          </div>

                          <div className="col-4">
                            <p className="BasicDataItemHeader">Kalorien</p>
                            <p className="BasicDataItemBody">
                              {meal.recipe.nutrition.length == 0 ? (
                                <div></div>
                              ) : (
                                meal.recipe.nutrition.find(
                                  (nutrition) =>
                                    nutrition.name === "Energie (kcal)"
                                ).amount || 0
                              )}
                            </p>
                          </div>

                          <div className="col-4">
                            <p className="BasicDataItemHeader">Protein</p>
                            <p className="BasicDataItemBody">
                              {meal.recipe.nutrition.length == 0 ? (
                                <div></div>
                              ) : (
                                meal.recipe.nutrition.find(
                                  (nutrition) => nutrition.name === "Eiweiß"
                                ).amount || 0
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="col-md-6 margincards "
                key={"menucard_col_" + meal.recipe.name}
              >
                <div className="flexContainer  h-100">
                  <Card
                    className="WeeklyMenuCard h-100"
                    key={"menucard_" + meal.recipe.name}
                  >
                    <Link to={"/recipe/" + meal.recipe.id}>
                      <div className="singleCardPicture">
                        <CardMedia
                          component="img"
                          image={
                            "https://img.hellofresh.com/q_auto/recipes/" +
                            meal.recipe.imagePath
                          }
                          alt="green iguana"
                        />
                      </div>
                    </Link>

                    <div className="detailsUnderPicture">
                      <h2 className="textCardOverview">{meal.recipe.name}</h2>
                      <h4 className="subtextCardOverview">
                        {meal.recipe.headline}
                      </h4>

                      <div>
                        <div className="row containerBasicData">
                          <div className="col-4">
                            <p className="BasicDataItemHeader">Kochzeit</p>
                            <p className="BasicDataItemBody">
                              {meal.recipe.prepTime.slice(2)}
                            </p>
                          </div>

                          <div className="col-4">
                            <p className="BasicDataItemHeader">Kalorien</p>
                            <p className="BasicDataItemBody">
                              {meal.recipe.nutrition.length == 0 ? (
                                <div></div>
                              ) : (
                                meal.recipe.nutrition.find(
                                  (nutrition) =>
                                    nutrition.name === "Energie (kcal)"
                                ).amount || 0
                              )}
                            </p>
                          </div>

                          <div className="col-4">
                            <p className="BasicDataItemHeader">Protein</p>
                            <p className="BasicDataItemBody">
                              {meal.recipe.nutrition.length == 0 ? (
                                <div></div>
                              ) : (
                                meal.recipe.nutrition.find(
                                  (nutrition) => nutrition.name === "Eiweiß"
                                ).amount || 0
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default WeeklyMenuAll;
