import React, { Component, useEffect, useState } from "react";
import ComponentOverviewWeeklyMenu from "../components/componentOverviewWeeklyMenu";
import http from "../service/httpService";
import MenuData from "../data/weekyMenuWithoutToken.json";
import LoadingScreen from "../components/loading";
MenuData.meals = MenuData.pageProps.ssrPayload.menu.courses;

function WeeklyMenus(props) {
  //let [data, setData] = useState(MenuData); //for Testing without Backend on
  let [data, setData] = useState({});
  useEffect(() => {
    let isCancelled = false;
    async function callApi() {
      try {
        console.log(process.env.REACT_APP_API_URL);
        let { data } = await http.get(
          process.env.REACT_APP_API_URL + "/api/weeklymenu/"
        );

        let dataSingleMenu = data[0];
        dataSingleMenu.meals = data[0].courses;
        if (!isCancelled) {
          setData(dataSingleMenu);
          console.log("Erhalten", dataSingleMenu);
        }
      } catch (error) {
        console.log("error   " + error);
      }
    }
    callApi();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div>
      {!data.meals ? (
        <LoadingScreen />
      ) : (
        <div>
          <ComponentOverviewWeeklyMenu data={data} />
        </div>
      )}
    </div>
  );
}

export default WeeklyMenus;
