import React, { Component, Fragment } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faHeart,
  faCalendarDays,
  faBook,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Fragment>
      <nav className="navbar fixed-bottom navbar-light bg-light nopadding navbarheight">
        <form className="form-inline centerNavbar width100">
          <div className="container">
            <div className="row">
              <Link to="/" className="col align-self-center nopadding">
                <button className="btn btn-light width100" type="button">
                  <FontAwesomeIcon icon={faCalendarDays} size="lg" />
                  <div className="subtextButton">Aktuelle Woche</div>
                </button>
              </Link>
              <Link
                to="/likedMenus"
                className="col align-self-center nopadding"
              >
                <button className="btn btn-light width100" type="button">
                  <FontAwesomeIcon icon={faHeart} size="lg" />
                  <div className="subtextButton">Favoriten</div>
                </button>
              </Link>
              <Link to="/search" className="col align-self-center nopadding">
                <button className="btn btn-light width100" type="button">
                  <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                  <div className="subtextButton">Suche</div>
                </button>
              </Link>
              <Link
                to="/wantToMakeMenus"
                className="col align-self-center nopadding"
              >
                <button className="btn btn-light width100" type="button">
                  <FontAwesomeIcon icon={faBook} size="lg" />
                  <div className="subtextButton">Zu kochen</div>
                </button>
              </Link>
              <Link
                to="/shoppingList"
                className="col align-self-center nopadding"
              >
                <button className="btn btn-light width100" type="button">
                  <FontAwesomeIcon icon={faCartShopping} size="lg" />
                  <div className="subtextButton">Einkaufsliste</div>
                </button>
              </Link>
            </div>
          </div>
        </form>
      </nav>
    </Fragment>
  );
};

export default Navbar;
