import React, { Component, useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  fa2,
  fa3,
  fa4,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart as faHeartSolid,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import http from "../service/httpService";
import { useParams } from "react-router-dom";
import IncredientListContext from "../context/IncredientListContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LooksFourIcon from "@mui/icons-material/Looks4";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingScreen from "../components/loading";
import exampleData from "../data/singleMenu.json";
import ScrollToTop from "../components/ScrollToTop";

const SingleMenu = () => {
  let { incList, setIncList } = useContext(IncredientListContext);

  //Toast Succesfull added to Cart

  const notifySuccessShopping = (boolean) => {
    let liked = "Erfolgreich entfernt";
    if (boolean) {
      liked = "Erfolgreich hinzugefügt";
    }

    toast.success(liked, {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccessLike = (boolean) => {
    let liked = "Erfolgreich unliked";
    if (boolean) {
      liked = "Erfolgreich geliked";
    }

    toast.success(liked, {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccess = () =>
    toast.success("Erfolgreich hinzugefügt", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notify = () =>
    toast(
      ({ closeToast }) => (
        <div className="container">
          <div className="row ">
            <h2 className="alignToast">Für wie viel Personen hinzufügen</h2>
          </div>
          <div className="row">
            <button
              onClick={() => handleButtonAddToCart(0)}
              type="button"
              className="col-4 btn btn-outline-dark nopadding "
            >
              <FontAwesomeIcon icon={fa2} size="m" />
            </button>
            <button
              onClick={() => handleButtonAddToCart(1)}
              type="button"
              className="col-4 btn btn-outline-dark "
            >
              <FontAwesomeIcon icon={fa3} size="m" />
            </button>
            <button
              onClick={() => handleButtonAddToCart(2)}
              type="button"
              className="col-4 btn btn-outline-dark "
            >
              <FontAwesomeIcon icon={fa4} size="m" />
            </button>
          </div>
        </div>
      ),
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 15000,
        hideProgressBar: true,
      }
    );

  const allreadyDone = () =>
    toast(
      () => (
        <div className="container">
          <div className="row justify-content-center">
            <h2 className="">Gericht bereits gekocht?</h2>
          </div>
          <div className="row justify-content-center">
            <button
              onClick={() => wannaCook(true)}
              type="button"
              className="col-4 col-md-auto btn btn-outline-dark"
            >
              <FontAwesomeIcon icon={faCircleCheck} size="lg" />
            </button>
          </div>
        </div>
      ),
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 15000,
        hideProgressBar: true,
      }
    );

  function handleButtonAddToCart(amount) {
    for (let i = 0; i < MenuData.yields[amount].ingredients.length; i++) {
      let sysIdYields = MenuData.yields[amount].ingredients[i].id;
      const nameOfYield = MenuData.ingredients.find(
        (incredient) => incredient.id === sysIdYields
      );

      let yieldsObjWithName = MenuData.yields[amount].ingredients[i];
      yieldsObjWithName.name = nameOfYield.name;

      incList = IncredientListContext.addIncredient(incList, yieldsObjWithName);
    }
    setIncList(incList);

    //Mark Menu as wanna cook
    wannaCook(false);

    // let { incList, setIncList } = useContext(IncredientListContext);
  }

  //const [MenuData, setMenuData] = useState(exampleData); //for testing
  const [MenuData, setMenuData] = useState([]);

  let { menuid } = useParams();

  async function markLiked() {
    try {
      let changeHeartIcon = true;
      if (MenuData.like) {
        changeHeartIcon = false;
      }
      MenuData.like = changeHeartIcon;
      MenuData.changerno = "chango" + changeHeartIcon;

      let { data } = await http.post(
        process.env.REACT_APP_API_URL + "/api/likemenu/" + menuid
      );
      setMenuData(MenuData);

      const f = () => {
        setMenuData({ ...MenuData });
      };
      f();
      notifySuccessLike(changeHeartIcon);
    } catch (error) {
      console.log("error " + error);
    }
  }

  async function wannaCook(settofalse) {
    try {
      let changeCook = true;
      console.log("Daaata" + MenuData.cook + settofalse);
      if (settofalse) {
        changeCook = false;
      }
      MenuData.cook = changeCook;

      //if allready in wantToCook dont call api
      if (settofalse || changeCook) {
        let { data } = await http.post(
          process.env.REACT_APP_API_URL + "/api/cookmenu/" + menuid
        );

        setMenuData(MenuData);

        const f = () => {
          setMenuData({ ...MenuData });
        };
        f();
      }
      notifySuccessShopping(changeCook);
    } catch (error) {
      console.log("error " + error);
    }
  }

  useEffect(() => {
    let isCancelled = false;
    console.log("Call Effect");
    async function callApi() {
      try {
        let response = await http.get(
          process.env.REACT_APP_API_URL + "/api/menu/" + menuid
        );
        console.log("Respi", response);
        let { data } = response;

        let dataSingleMenu = data[0];
        if (!dataSingleMenu) {
          dataSingleMenu = data;
        }
        if (!isCancelled) {
          setMenuData(dataSingleMenu);
        }
      } catch (error) {
        console.log("error " + error);
      }
    }
    callApi();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div>
      {!MenuData.name ? (
        <LoadingScreen />
      ) : (
        <div>
          <div className="flexContainer">
            <Card className="SingleMenuCard">
              <div className="singleCardPicture">
                {!MenuData.name ? (
                  ""
                ) : (
                  <CardMedia
                    component="img"
                    image={
                      "https://img.hellofresh.com/q_auto/recipes/" +
                      MenuData.imagePath
                    }
                    alt="green iguana"
                  />
                )}
                <div className="singleCardTextDiv">
                  <h1 className="singleCardText2">{MenuData.name}</h1>
                  <h3 className="singleCardTextSecondary2">
                    {MenuData.headline}
                  </h3>
                </div>
              </div>

              <div className="borderBottom ">
                <div className="row containerBasicData">
                  <div className="col-4 nopadding">
                    <p className="BasicDataItemHeader">Kochzeit</p>
                    <p className="BasicDataItemBody">
                      {MenuData.totalTime.slice(2)}
                    </p>
                  </div>

                  <div className="col-4 nopadding">
                    <p className="BasicDataItemHeader">Kalorien</p>
                    <p className="BasicDataItemBody">
                      {/*MenuData.nutrition[1].amount*/}
                      {MenuData.nutrition.length == 0 ? (
                        <div></div>
                      ) : (
                        MenuData.nutrition.find(
                          (nutrition) => nutrition.name === "Energie (kcal)"
                        ).amount || 0
                      )}
                    </p>
                  </div>

                  <div className="col-4 nopadding">
                    <p className="BasicDataItemHeader">Schwierigkeitsgrad</p>
                    <p className="BasicDataItemBody">{MenuData.difficulty}</p>
                  </div>
                </div>
              </div>

              {/*Incredient*/}
              <div className="incredients borderBottom">
                <h1>
                  Zutaten <span className="NumberPerson">// 2Personen</span>
                </h1>

                <div className="row">
                  {!MenuData.ingredients
                    ? ""
                    : MenuData.ingredients.map((ingredient) => {
                        return ingredient.shipped == true ? (
                          <div
                            className="col-6 IngredientItem"
                            key={"incredient_" + ingredient.name}
                          >
                            <div className="row align-items-center flex-nowrap">
                              <img
                                src={
                                  "https://img.hellofresh.com/c_fill,f_auto,fl_lossy,h_40,q_auto,w_40/hellofresh_s3" +
                                  ingredient.imagePath
                                }
                                alt={"image_" + ingredient.name}
                              ></img>
                              <div className="descriptonIncredients">
                                <span>
                                  <p>
                                    {
                                      MenuData.yields[0].ingredients.find(
                                        (x) => x.id === ingredient.id
                                      ).amount
                                    }{" "}
                                    {
                                      MenuData.yields[0].ingredients.find(
                                        (x) => x.id === ingredient.id
                                      ).unit
                                    }
                                  </p>
                                </span>
                                <div className="descriptionIncredientsText">
                                  {ingredient.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                </div>
              </div>

              {/*Should have at home*/}
              <div className="incredients borderBottom">
                <h3>Was Du zu Hause haben solltest</h3>

                <div className="row">
                  {!MenuData.ingredients
                    ? ""
                    : MenuData.ingredients.map((ingredient) => {
                        return ingredient.shipped == false ? (
                          <div
                            className="col-6 IngredientItem"
                            key={"incredient_" + ingredient.name}
                          >
                            <div className="row align-items-center flex-nowrap">
                              <img
                                src={
                                  "https://img.hellofresh.com/c_fill,f_auto,fl_lossy,h_40,q_auto,w_40/hellofresh_s3" +
                                  ingredient.imagePath
                                }
                                alt={"image_" + ingredient.name}
                              ></img>
                              <div className="descriptonIncredients ">
                                <span>
                                  <p>
                                    {
                                      MenuData.yields[0].ingredients.find(
                                        (x) => x.id === ingredient.id
                                      ).amount
                                    }{" "}
                                    {
                                      MenuData.yields[0].ingredients.find(
                                        (x) => x.id === ingredient.id
                                      ).unit
                                    }
                                  </p>
                                </span>
                                {ingredient.name}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                </div>
              </div>

              {/*Kochutensilien*/}
              <div className="Kochutensilien incredients borderBottom">
                <h1>Kochutensilien</h1>
                <ul className="row">
                  {!MenuData.utensils
                    ? ""
                    : MenuData.utensils.map((utensils) => {
                        return (
                          <li
                            className="col-4 nopadding kochutensilien "
                            key={"utensils_" + utensils.name}
                          >
                            {utensils.name}
                          </li>
                        );
                      })}
                </ul>
              </div>

              {/*Zubereitung*/}
              <div className="Kochutensilien incredients">
                <h1>Zubereitung</h1>
                <div className="row">
                  {!MenuData.steps
                    ? ""
                    : MenuData.steps.map((step) => {
                        return (
                          <div className="col-md-6" key={"step_" + step.index}>
                            <div className="singleCardPicture">
                              <img
                                src={
                                  "https://img.hellofresh.com/f_auto,fl_lossy,q_auto/hellofresh_s3" +
                                  step.images[0].path
                                }
                                alt={"image_" + step.index}
                              ></img>
                              <h1 className="singleStepText">{step.index}</h1>
                            </div>

                            <h3 className="headerStep">
                              {step.images[0].caption}
                            </h3>
                            <div className="bottomStep">
                              {step.instructions}
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </Card>
          </div>

          <div className="flexContainer ">
            <Card className="buttonCard ">
              {/*Button*/}

              {MenuData.cook ? (
                <div className="row nopadding">
                  <button
                    type="button"
                    className="col-4 btn btn-outline-dark"
                    onClick={markLiked}
                  >
                    {MenuData.like ? (
                      <FontAwesomeIcon icon={faHeartSolid} size="lg" />
                    ) : (
                      <FontAwesomeIcon icon={faHeart} size="lg" />
                    )}
                  </button>

                  <button
                    onClick={notify}
                    type="button"
                    className="col-4 btn btn-outline-dark"
                  >
                    <FontAwesomeIcon icon={faCartArrowDown} size="lg" />
                  </button>
                  <button
                    onClick={allreadyDone}
                    type="button"
                    className="col-4 btn btn-outline-dark"
                  >
                    <FontAwesomeIcon icon={faCircleCheck} size="lg" />
                  </button>
                </div>
              ) : (
                <div className="row nopadding">
                  <button
                    type="button"
                    className="col-6 btn btn-outline-dark"
                    onClick={markLiked}
                  >
                    {MenuData.like ? (
                      <FontAwesomeIcon icon={faHeartSolid} size="lg" />
                    ) : (
                      <FontAwesomeIcon icon={faHeart} size="lg" />
                    )}
                  </button>

                  <button
                    onClick={notify}
                    type="button"
                    className="col-6 btn btn-outline-dark"
                  >
                    <FontAwesomeIcon icon={faCartArrowDown} size="lg" />
                  </button>
                </div>
              )}
            </Card>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default SingleMenu;
