import { useEffect, useState } from "react";
import axios from "axios";

export default function useMenuSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [menus, setMenus] = useState([]);
  const [dataMenu, setDataMenu] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setMenus([]);
  }, [query]);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    setError(false);

    axios({
      method: "GET",
      url: process.env.REACT_APP_API_URL + "/api/search",
      params: { q: query, take: 16, skip: pageNumber },
      signal: controller.signal,
    })
      .then((res) => {
        let menuArray = [];
        //for (let x = 0; x < jsonRes.meals.length; x++) {
        //    menuArray.push(jsonRes.meals);
        //  }

        // setMenus(res.data.meals);
        setMenus((prevMenus) => {
          let extendedMenu = [
            ...new Set([...prevMenus, ...res.data.meals.map((b) => b)]),
          ];

          let uniqueExtendedMenu = [];
          function searchMenuInArray(object, array) {
            for (let z = 0; z < array.length; z++) {
              if (!array[z]) return false;

              if (array[z].recipe.name === object.recipe.name) {
                return true;
              }
            }
            return false;
          }

          for (let c = 0; c < extendedMenu.length; c++) {
            if (c == 0) {
              uniqueExtendedMenu.push(extendedMenu[c]);
            }

            if (!searchMenuInArray(extendedMenu[c], uniqueExtendedMenu)) {
              uniqueExtendedMenu.push(extendedMenu[c]);
            }
          }
          return uniqueExtendedMenu;
        });

        setDataMenu({ meals: menus });
        setHasMore(res.data.total - pageNumber > 16);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
      });
    // return () => cancel();
    return () => {
      controller.abort();
    };
  }, [query, pageNumber]);

  return { loading, error, menus, hasMore, dataMenu };
}
