import React, { Component, useState, useEffect } from "react";
import jwt from "jwt-decode";

const NavbarTop = () => {
  const [user, setUser] = useState("Bitte einloggen");

  useEffect(() => {
    const token = localStorage.getItem("token");
    var decodedToken = jwt(token);
    //https://img.hellofresh.com/image/upload/c_scale,f_auto,q_100,w_435/v1600959926/Hello_Fresh_Lockup.png
    if (decodedToken) {
      setUser(decodedToken.email);
    }
  }, [user]);

  return (
    <div className="container">
      <nav className="navbar navbar-light bg-logo justify-content-center fixed-top widthFull">
        <div className="navbar-brand">
          <div className="containerLogoFresh">
            <div className="row ">
              <div className="col nopadding ">
                <img
                  src="/images/logonavbar1.png"
                  width="55"
                  height="45"
                  className="logoLemon"
                  alt=""
                ></img>
              </div>
              <div className="col nopadding">
                <img
                  src="/images/logonavbar2.png"
                  width="120"
                  height="24"
                  className=""
                  alt=""
                ></img>
                <div className="logoUser">{user}</div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarTop;
