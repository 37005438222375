import React from "react";
import http from "../service/httpService";

const IncredientListContext = React.createContext({
  incList: [],
  setIncList: () => {},
});

let incredientList = [];

//SendList to Server

async function sendIngredientList(incredientList) {
  try {
    var headers = {
      "Content-Type": "application/json",
    };
    var body = {
      list: incredientList,
    };

    let { data } = await http.post(
      process.env.REACT_APP_API_URL + "/api/shoppinglist/",
      body,
      { headers: headers }
    );
  } catch (error) {
    console.log("error " + error);
  }
}

function addIncredient(incredientList, incredientObj) {
  //Check if Incredient exist in list

  if (incredientObj.unit != "nach Geschmack") {
    const result = incredientList.find(
      (incredient) =>
        incredient.id === incredientObj.id &&
        incredient.unit === incredientObj.unit
    );

    //if exist add amount

    if (result) {
      // add old + new amount

      result.amount += incredientObj.amount;

      incredientList[incredientList.indexOf(result)] = result;
    } else {
      //add complete obj to list

      incredientList.push(incredientObj);
    }
  }
  sendIngredientList(incredientList);
  return incredientList;
}

function removeIncredient(incredientList, incredientID) {
  //Check if Incredient exist in list

  const result = incredientList.find(
    (incredient) => incredient.id === incredientID
  );

  //if exist add amount

  if (result) {
    //remove IncredientObj from List

    incredientList.splice(incredientList.indexOf(result), 1);
  } else {
    //do nothing
  }
  sendIngredientList(incredientList);
  return incredientList;
}

function removeIncredientByArray(incList, removeArray) {
  for (let o = 0; o < removeArray.length; o++) {
    removeIncredient(incList, removeArray[o]);
  }
  return incList;
}

//Assign Functionts to Context
IncredientListContext.addIncredient = addIncredient;
IncredientListContext.removeIncredient = removeIncredient;
IncredientListContext.removeIncredientByArray = removeIncredientByArray;

export default IncredientListContext;
