import React from "react";
import ReactLoading from "react-loading";

const LoadingPage = ({ type, color }) => (
  <div className="loading-page">
    <ReactLoading
      type={"bars"}
      color={"#96dc14"}
      height={"20%"}
      width={"20%"}
    />
  </div>
);

export default LoadingPage;
