import { Navigate, Outlet } from "react-router-dom";
import { useState } from "react";

const PrivateRoutes = () => {
  const token = localStorage.getItem("token");

  //  if (decodedToken) setUser(decodedToken.email);
  return token ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoutes;
