import React, {
  Component,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import ComponentOverviewWeeklyMenu from "../components/componentOverviewWeeklyMenu";
import http from "../service/httpService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../components/loading";
import useMenuSearch from "../service/searchMenus";

function WeeklyMenus(props) {
  let [data, setData] = useState({});

  /*
  let [inputQuery, setInputQuery] = useState("");

  async function callApi(query) {
    try {
      let isCancelled = false;
      console.log(process.env.REACT_APP_API_URL);
      let { data } = await http.get(
        process.env.REACT_APP_API_URL + "/api/search?q=" + query
      );
      console.log("daaaata", data);
      let dataSingleMenu = data;

      if (!isCancelled) {
        setData(dataSingleMenu);
        console.log("Erhalten", dataSingleMenu);
      }
    } catch (error) {
      console.log("error   " + error);
    }
  }

  let textInput = React.createRef(); // React use ref to get input value

  let handleSearchButton = (e) => {
    e.preventDefault();
    callApi(textInput.current.value);
    console.log("click", textInput.current.value);
  };
*/
  let textInput = React.createRef(); // React use ref to get input value

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const { menus, hasMore, loading, error, dataMenu } = useMenuSearch(
    query,
    pageNumber
  );

  const observer = useRef();
  const lastMenuElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    e.preventDefault();
    setQuery(e.target.value || textInput.current.value);
    setPageNumber(0);
  }

  return (
    <div>
      <div>
        <form onSubmit={handleSearch}>
          <div className="input-group searchBar">
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              value={query}
              onChange={handleSearch}
              ref={textInput}
            />
            <button type="submit" className="btn btn-outline-success">
              search
            </button>
          </div>
        </form>
      </div>

      {!menus || menus.length == 0 ? (
        <div></div>
      ) : (
        <div>
          <ComponentOverviewWeeklyMenu
            data={{ meals: menus }}
            query={query}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      )}

      <div>{loading && <LoadingScreen />}</div>
      <div>{error && "Error"}</div>
    </div>
  );
}

export default WeeklyMenus;
