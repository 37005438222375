import React, { Component } from "react";
import NavbarLogoTop from "./navbarLogo";
import Navbar from "./navbar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="App FullScreen">
      <NavbarLogoTop />
      <Outlet />
      <Navbar />
    </div>
  );
};

export default Layout;
