import axios from "axios";
let token = localStorage.getItem("token");

axios.defaults.headers.common["x-auth-token"] = token;
//axios.defaults.headers.common["Access-Control-Allow-Origin"] =("https://finanzgul.herokuapp.com");
//axios.defaults.headers.common["Access-Control-Allow-Methods"] =("GET,PUT,POST,DELETE,PATCH,OPTIONS");

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("Logging the error", error);
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
