import React, { Component, useEffect, useState } from "react";
import ComponentOverviewWeeklyMenu from "../components/componentOverviewWeeklyMenu";
import http from "../service/httpService";
import MenuData from "../data/weekyMenuWithoutToken.json";
MenuData.meals = MenuData.pageProps.ssrPayload.menu.courses;

function WeeklyMenus(props) {
  let [data, setData] = useState(MenuData);

  useEffect(() => {
    let isCancelled = false;
    async function callApi() {
      try {
        let { data } = await http.get(
          process.env.REACT_APP_API_URL + "/api/cookmenu/"
        );

        let dataSingleMenu = data;
        for (let i = 0; i < dataSingleMenu.length; i++) {
          dataSingleMenu[i].recipe = data[i];
          dataSingleMenu[i].recipe.id = data[i].idMenu;
        }

        dataSingleMenu.meals = data;
        if (!isCancelled) {
          setData(dataSingleMenu);
        }
      } catch (error) {
        console.log("error " + error);
      }
    }
    callApi();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div>
      <ComponentOverviewWeeklyMenu data={data} />
    </div>
  );
}

export default WeeklyMenus;
